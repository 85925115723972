
/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator';
import { JoinModule } from '@/store/join';
import { Form } from 'element-ui';

@Component({
  name: 'cancelStep2',
})

export default class extends Vue {

  get form() {
    return JoinModule.form;
  }

  private categoryEvent() {
    if(this.form.category==4){
      this.categoryFlag = true;
    } else {
      this.categoryFlag = false;
    }
  }

  private categoryFlag: boolean = false;


  private rules: any = {
    category: [
      { required: 'true', message: '카테고리를 선택해주세요.', trigger: ['blur', 'change'] },
    ],
    content: [
      { required: 'true', message: '상세사유를 입력해주세요.', trigger: ['blur', 'change'] },
    ],
  };

  private handleCancel() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        if(this.categoryFlag){
          const str = (this.$refs.content as any).value;
          if(str.length==0){
            alert("상세사유는 필수 값입니다.");
            return false;
          }
        }
        this.$router.push({ name: 'cancelStep3' }).catch(() => {
         this.$router.push({ name: 'cancelStep3' });
        });
      }
    });
  }
}
